import React from "react";
import * as styles from "./footer.module.css";

const Footer = () => (
  <nav className={styles.container}>
    <ul className={styles.list}>
      {/* <li><Link to="/privacy/">Privacy</Link></li>
      <li><span>|</span></li>
      <li><Link to="/legal/">Legal</Link></li> */}
    </ul>
  </nav>
);

export default Footer;
